/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2012/08/legos.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 380px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 69.62025316455697%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABBP/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAUj1eENWyQ//xAAcEAABBAMBAAAAAAAAAAAAAAACAQMEExASITH/2gAIAQEAAQUClb1syTHDg8oVTTz/xAAXEQEAAwAAAAAAAAAAAAAAAAABAhAx/9oACAEDAQE/ASJrX//EABgRAAIDAAAAAAAAAAAAAAAAAAEREBIT/9oACAECAQE/AdC6qP/EABsQAAIBBQAAAAAAAAAAAAAAAAEQEQASISKB/9oACAEBAAY/AtO1ByHdIX//xAAbEAADAQEAAwAAAAAAAAAAAAAAAREhQTFRcf/aAAgBAQABPyFmde3WmEyyPyhOqmmR/SO0OoWQf//aAAwDAQACAAMAAAAQU+//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QPov/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QFCP/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAxIUFRYYH/2gAIAQEAAT8QQYCCIR4Yje7kz5D3+5IhKaess1C61wL6ZAecjeM//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Bloques de Lego\"\n        title=\"legos\"\n        src=\"/static/62500ce4f53ef71774ef172ce2d8a77a/0246a/legos.jpg\"\n        srcset=\"/static/62500ce4f53ef71774ef172ce2d8a77a/ff44c/legos.jpg 158w,\n/static/62500ce4f53ef71774ef172ce2d8a77a/a6688/legos.jpg 315w,\n/static/62500ce4f53ef71774ef172ce2d8a77a/0246a/legos.jpg 380w\"\n        sizes=\"(max-width: 380px) 100vw, 380px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "La clave de la ", React.createElement(_components.a, {
    href: "http://bitacora.lasindias.com/la-revolucion-p2p-contada-en-10-minutos/",
    title: "La Revolución P2P en 10 min"
  }, "Revolución P2P"), " está en la reducción de la escala y en compartir el diseño, pero para compartir diseños y que tengan utilidad para el resto del mundo es necesario utilizar dos criterios, el diseño modular y la personalización, hoy pienso hablaros del primero."), "\n", React.createElement(_components.p, null, "El diseño modular tiene su origen en la era industrial, no todo lo que fue buena idea en el S. XX es desechable en la era informacional y éste es un buen ejemplo. En contraposición al diseño artesano e incluso al ", React.createElement(_components.a, {
    href: "http://www.genbetadev.com/bases-de-datos/diseno-emergente-en-la-base-de-datos",
    title: "Diseño emergente en la base de datos"
  }, "diseño emergente"), " que se producía hasta entonces, el ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Fordismo"
  }, "Fordismo"), " propuso un sistema de producción que prácticamente eliminaba la personalización, cada pieza producida tenia que ser igual a la anterior."), "\n", React.createElement(_components.p, null, "¿Cual fue entonces la única forma de incorporar algo de la ", React.createElement(_components.a, {
    href: "http://personotecnia.wordpress.com/"
  }, "personalización"), " tan necesaria a pesar de todo? La aparición del diseño modular."), "\n", React.createElement(_components.p, null, "Es decir, crear muchas piezas iguales de varios tipos y facilitar la forma de combinarlas entre ellas de la forma que nosotros queramos."), "\n", React.createElement(_components.p, null, "Este tipo de diseño no sólo se utiliza en la propia producción si no incluso en las herramientas de diseño; por ejemplo en los programas CAD, como los bloques de AutoCAD y en el nuevo paradigma de la arquitectura. El BIM, del que posiblemente hable dentro de unos días."), "\n", React.createElement(_components.p, null, "El diseño modular también se ha demostrado un potente aliado de la programación, es la base de lo que se conoce como Programación Orientada a Objetos."), "\n", React.createElement(_components.p, null, "Podría extenderme, pero creo que ya he dado una idea de las ventajas del diseño por módulos. Por todo esto estoy convencido que el diseño modular va a ser clave en la 3ª Revolución Industrial, la que está llegando ya en estos tiempos de crisis."), "\n", React.createElement(_components.p, null, "Para que todo esto no caiga en saco roto voy a poner un ejemplo real, Open Source Ecology tiene como objetivo el diseño de su ", React.createElement(_components.a, {
    href: "http://opensourceecology.org/gvcs.php",
    title: "GVCS"
  }, "Global Village Construction Set"), ", un conjunto de maquinas industriales de código abierto que se construyen de forma modular, rebajando su coste de esta forma, es uno de los mejores ejemplos que lo que va a venir con la Revolución P2P."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
